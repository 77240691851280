var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "list" },
    _vm._l(_vm.elements.groups, function (group, index) {
      return _c(
        "div",
        { key: group.auxId || index, staticClass: "list__item" },
        [
          _c("BlockItem", {
            attrs: {
              node: group,
              "is-last-group-prop": _vm.verifyIsLastGroup(index),
              "create-condition-node-prop": _vm.verifyToCreateConditionNode,
              "create-group-prop": _vm.verifyToCreateGroup,
              "delete-line-prop": _vm.deleteLineOnNode,
              "delete-group-prop": _vm.deleteGroup,
              "pickup-element-prop": _vm.pickupElement,
            },
            on: { loadingLargeData: _vm.loadingLargeData },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }