var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      {
        staticClass: "content",
        on: {
          drop: function ($event) {
            $event.preventDefault()
            return _vm.onDrop($event)
          },
          dragenter: function ($event) {
            $event.preventDefault()
          },
          dragover: function ($event) {
            $event.preventDefault()
          },
        },
      },
      [
        _vm.node.conditions.length
          ? _vm._l(_vm.node.conditions, function (item, idx) {
              return _c("BlockListLine", {
                key: item.auxId,
                attrs: {
                  info: item,
                  itemIndexProp: idx,
                  isLastChildProp: _vm.isLastChild(item),
                },
                on: {
                  deleteLine: _vm.deleteLine,
                  pickElement: _vm.pickupElement,
                  onDropItem: _vm.onDropItem,
                  loadingLargeData: _vm.loadingLargeData,
                },
              })
            })
          : [_vm._m(0)],
      ],
      2
    ),
    !_vm.isLastGroupProp
      ? _c(
          "div",
          { staticClass: "content__condiction mb-4" },
          [
            _c(
              "v-row",
              { staticClass: "d-flex justify-space-between" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", sm: "12", md: "2", lg: "2" } },
                  [
                    _c("z-select", {
                      attrs: {
                        items: _vm.itemsBlock,
                        itemText: "text",
                        itemValue: "value",
                        type: "text",
                        hideDetails: true,
                      },
                      model: {
                        value: _vm.node.relation,
                        callback: function ($$v) {
                          _vm.$set(_vm.node, "relation", $$v)
                        },
                        expression: "node.relation",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticClass: "d-flex justify-end",
                    attrs: { cols: "12", sm: "12", md: "2", lg: "2" },
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.deleteGroupProp(_vm.node)
                          },
                        },
                      },
                      [_vm._v("mdi-delete")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      : _c("div", { staticClass: "content__condiction" }, [
          _c(
            "span",
            {
              on: {
                click: function ($event) {
                  return _vm.createGroupProp(_vm.node)
                },
              },
            },
            [_vm._v("+ Adicionar novo grupo")]
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "no-data" }, [
      _c("span", [_vm._v("Arraste a coluna para filtrar")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }