<template>
  <div
    :draggable="isDraggable"
    @dragover.prevent
    @dragstart.self="pickElement($event, info)"
    @drop="onDropItem($event, info, itemIndexProp)"
    class="line"
    @mouseout="isDraggable = false"
  >
    <v-row dense>
      <!-- SEÇÃO DE BOTÃO DE MOVIMENTO -->
      <v-col cols="12" sm="3" lg="3" md="3">
        <div class="line__item">
          <v-icon style="cursor: pointer" @mousedown="isDraggable = true">
            $move_element
          </v-icon>
          <z-input
            v-model="info.field"
            placeholder=""
            type="text"
            :hideDetails="true"
            readonly
          />
        </div>
      </v-col>

      <!-- SELEÇÃO DE OPERADOR -->
      <v-col cols="12" sm="3" lg="3" md="3">
        <div class="line__item">
          <z-select
            v-model="info.operator"
            :items="
              info.type.toLowerCase() == 'string' || info.type.toLowerCase() == 'text'
                ? itemsString
                : info.type.toLowerCase() == 'integer' ||
                  info.type.toLowerCase() == 'decimal'
                ? itemsNumber
                : info.type.toLowerCase() == 'date' ||
                  info.type.toLowerCase() == 'datetime'
                ? itemsDate
                : info.type.toLowerCase() == 'boolean'
                ? itemsBool
                : info.type.toLowerCase() == 'template-email'
                ? itemsTemplateEmail
                : info.type.toLowerCase() == 'template-sms'
                ? itemsTemplateSms
                : info.type.toLowerCase() == 'tag'
                ? itemsTemplateTag
                : []
            "
            type="text"
            itemText="text"
            itemValue="value"
            :hideDetails="true"
            label="Selecione o operador"
            @change="
              (info.value = null),
                searchColumnItems(info.operator, info),
                (selectAllData = false)
            "
          />
        </div>
      </v-col>

      <!-- SELEÇÃO DOS CAMPOS -->
      <v-col cols="12" sm="6" lg="6" md="6">
        <v-row dense>
          <v-col cols="12" sm="11" md="11" lg="11">
            <div v-if="info.operator && shouldShowOptions(info.operator)">
              <!-- QUANDO FOR RELATIVO -->
              <div
                v-if="
                  info.operator == 'equals-bday' ||
                  info.operator == 'equals-rel' ||
                  info.operator == 'greater-rel' ||
                  info.operator == 'lesser-rel'
                "
                class="line__item"
              >
                <z-select
                  v-model="info.time_interval"
                  :items="itemsRelativeBday"
                  itemText="text"
                  itemValue="value"
                  placeholder=""
                  label="Selecione o período"
                  type="text"
                  :hideDetails="true"
                />
                <z-input
                  v-model="info.value"
                  placeholder="Modificador"
                  label="Modificador"
                  type="text"
                  :hideDetails="true"
                />
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      @click="selectOptionFromTable(item, 2)"
                      >$info_question_mark</v-icon
                    >
                  </template>
                  <span style="color: white; padding: 0.5rem; font-weight: 500"
                    >Esse valor será somado ao mês/dia atual. Exemplo: para ver
                    aniversários no mes anterior ao atual, usar -1 com o período mês, e
                    para ver os aniverários amanhã colocar modificador 1 com o período
                    dia</span
                  >
                </v-tooltip>
              </div>

              <div
                v-else-if="
                  info.operator == 'sent-rel' ||
                  info.operator == 'opened-rel' ||
                  info.operator == 'clicked-rel' ||
                  info.operator == 'received-rel' ||
                  info.operator == 'bounce-rel'
                "
                class="line__item"
              >
                <z-select
                  v-model="info.template_comparator"
                  :items="itemsComparator"
                  itemText="text"
                  itemValue="value"
                  placeholder=""
                  label="Selecione a comparação"
                  type="text"
                  :hideDetails="true"
                />
                <z-select
                  v-model="info.time_interval"
                  :items="itemsRelativeBday"
                  itemText="text"
                  itemValue="value"
                  placeholder=""
                  label="Selecione o período"
                  type="text"
                  :hideDetails="true"
                />
                <z-input
                  v-model="info.value"
                  placeholder="Modificador"
                  label="Modificador"
                  type="text"
                  :hideDetails="true"
                />
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      @click="selectOptionFromTable(item, 2)"
                      >$info_question_mark</v-icon
                    >
                  </template>
                  <span style="color: white; padding: 0.5rem; font-weight: 500"
                    >Esse valor será somado ao mês/dia atual. Exemplo: para ver
                    aniversários no mes anterior ao atual, usar -1 com o período mês, e
                    para ver os aniverários amanhã colocar modificador 1 com o período
                    dia</span
                  >
                </v-tooltip>
              </div>

              <div v-else-if="info.operator == 'between-rel'" class="line__item">
                <z-select
                  v-model="info.time_interval"
                  :items="itemsRelativeBday"
                  itemText="text"
                  itemValue="value"
                  placeholder=""
                  label="Selecione o período"
                  type="text"
                  :hideDetails="true"
                />
                <z-input
                  v-model="info.value1"
                  placeholder="Modificador De"
                  label="Modificador De"
                  type="text"
                  :hideDetails="true"
                />
                <z-input
                  v-model="info.value2"
                  placeholder="Modificador Ate"
                  label="Modificador Ate"
                  type="text"
                  :hideDetails="true"
                />
                <v-tooltip bottom color="grey">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="mr-1"
                      @click="selectOptionFromTable(item, 2)"
                      >$info_question_mark</v-icon
                    >
                  </template>
                  <span style="color: white; padding: 0.5rem; font-weight: 500"
                    >Esse valor será somado ao mês/dia atual. Exemplo: para ver
                    aniversários no mes anterior ao atual, usar -1 com o período mês, e
                    para ver os aniverários amanhã colocar modificador 1 com o período
                    dia</span
                  >
                </v-tooltip>
              </div>

              <!-- QUANDO FOR IGUAL OU DIFERENTE -->
              <div
                v-else-if="
                  (info.operator == 'equals' || info.operator == 'not-equal') &&
                  info.type.toLowerCase() != 'boolean'
                "
                class="line__item"
              >
                <z-autocomplete
                  label="Selecione o valor"
                  v-model="info.value"
                  :items="computedSearchColumns"
                  :search-input.sync="search"
                  :no-data-text="
                    loadingOptions ? 'Carregando...' : 'Nenhum dado disponível'
                  "
                  :itemText="info.type.toLowerCase() != 'date' ? 'text' : 'formated_date'"
                  multiple
                  type="text"
                  :hideDetails="true"
                  :key="autoCompleteKey"
                  :menu-props="{ eager: true }"
                  :eager="true"
                  :toggleAll="true"
                >
                  <template #selection="{}">
                    <span></span>
                  </template>
                </z-autocomplete>

                <v-btn
                  depressed
                  rounded
                  v-if="info.value && info.value.length"
                  color="#1976D2"
                  :style="getChipValueStyle()"
                  @click="dialog = true"
                >
                  {{ getChipValue() }}
                </v-btn>
              </div>

              <div
                v-else-if="
                  (info.operator == 'equals' || info.operator == 'not-equal') &&
                  info.type.toLowerCase() == 'boolean'
                "
                class="line__item"
              >
                <z-autocomplete
                  label="Selecione o valor"
                  v-model="info.value"
                  :items="booleanColumns"
                  itemText="text"
                  itemValue="value"
                  multiple
                  placeholder=""
                  type="text"
                  :hideDetails="true"
                >
                  <template #selection="{ attrs, item, select, selected, index }">
                    <v-chip
                      v-if="index === 0"
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      small
                      @click="select"
                      @click:close="removeItemOnSelect(item)"
                    >
                      {{ item }}
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text text-caption"
                      style="font-size: 0.75rem"
                    >
                      ( +{{ info.value.length - 1 }})
                    </span>
                  </template>
                </z-autocomplete>
              </div>

              <div v-else-if="info.operator == 'between'" class="line__item">
                <v-row dense class="line__item">
                  <v-col class="line__item">
                    <z-input
                      v-model="info.value1"
                      placeholder=""
                      label="Insira o primeiro valor"
                      type="text"
                      :hideDetails="true"
                    />
                  </v-col>
                  <v-col class="line__item">
                    <z-input
                      v-model="info.value2"
                      placeholder=""
                      label="Insira o segundo valor"
                      type="text"
                      :hideDetails="true"
                    />
                  </v-col>
                </v-row>
              </div>

              <div v-else-if="info.operator == 'between-date'" class="line__item">
                <v-row dense class="line__item">
                  <v-col class="line__item">
                    <z-input-date
                      v-model="info.value1"
                      class="mr-1"
                      label="Insira a primeira data"
                      format="pt-BR"
                      type="date"
                      :hideDetails="true"
                    />
                  </v-col>
                  <v-col class="line__item">
                    <z-input-date
                      v-model="info.value2"
                      class="mr-1"
                      label="Insira a segunda data"
                      format="pt-BR"
                      type="date"
                      :hideDetails="true"
                    />
                  </v-col>
                </v-row>
              </div>

              <!-- QUADO FOR DO TIPO POSSUI OU NÃO POSSUI -->

              <div
                v-else-if="info.operator == 'hasnot' || info.operator == 'has'"
                class="line__item"
              >
                <z-autocomplete
                  label="Selecione o valor"
                  v-model="info.value"
                  :items="computedSearchColumns"
                  :search-input.sync="search"
                  :no-data-text="
                    loadingOptions ? 'Carregando...' : 'Nenhum dado disponível'
                  "
                  :itemText="info.type.toLowerCase() != 'date' ? 'text' : 'formated_date'"
                  multiple
                  type="text"
                  :hideDetails="true"
                  :key="autoCompleteKey"
                  :menu-props="{ eager: true }"
                  :eager="true"
                  :toggleAll="true"
                >
                  <template #selection="{}">
                    <span></span>
                  </template>
                </z-autocomplete>
                <v-btn
                  depressed
                  rounded
                  v-if="info.value && info.value.length"
                  color="#1976D2"
                  :style="getChipValueStyle()"
                  @click="dialog = true"
                >
                  {{ getChipValue() }}
                </v-btn>
              </div>

              <div v-else class="line__item">
                <z-input
                  v-if="
                    info.type.toLowerCase() != 'date' &&
                    info.type.toLowerCase() != 'datetime'
                  "
                  v-model="info.value"
                  placeholder=""
                  label="Insira o valor"
                  type="text"
                  :hideDetails="true"
                />
                <z-input-date
                  v-else
                  v-model="info.value"
                  class="mr-1"
                  label="Insira a data"
                  format="pt-BR"
                  type="date"
                  :hideDetails="true"
                />
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            :md="info.operator && shouldShowOptions(info.operator) ? 1 : 12"
            :sm="info.operator && shouldShowOptions(info.operator) ? 1 : 12"
            :lg="info.operator && shouldShowOptions(info.operator) ? 1 : 12"
          >
            <div class="line__item" :class="{ 'mt-2': chageColumnsSize() }">
              <v-icon @click="$emit('deleteLine', info)"> $close_rounded</v-icon>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <div v-if="!isLastChildProp" class="content__condiction my-2">
      <v-row>
        <v-col cols="12" sm="12" md="3" lg="3">
          <z-select
            v-model="info.condictionRelation"
            :items="itemsBlock"
            itemText="text"
            itemValue="value"
            label="Selecione a relação"
            type="text"
            :hideDetails="true"
          />
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialog" width="500">
      <div class="wrapper__dialog">
        <div class="wrapper__dialog__title">
          <span>Editar filtro de {{ info.field }}</span>
        </div>
        <RecycleScroller
          :items="info.value"
          :item-size="40"
          key-field="id"
          v-slot="{ item, index }"
          style="height: 400px"
        >
          <span
            style="
              width: 100%;
              text-align: left;
              color: white;
              background-color: #1976d2;
              display: block;
              padding: 5px 15px;
              border-radius: 15px;
              margin-bottom: 1rem !important;
              border: 2px solid;
            "
          >
            {{ formateDate(item) }}

            <v-icon
              @click="info.value.splice(index, 1)"
              style="float: right; color: white; cursor: pointer"
            >
              $remove_circle_rounded</v-icon
            >
          </span>
        </RecycleScroller>

        <div class="wrapper__dialog--actions" style="justify-content: right">
          <z-btn
            text="Fechar"
            primary
            color="white"
            :rounded="true"
            style="border: 1px solid #1976d2 !important; color: #1976d2"
            @click="dialog = false"
          />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { RecycleScroller } from "vue-virtual-scroller";
import ApiClient from "@/services/ApiClient";
export default {
  props: {
    info: { type: Object, default: null },
    isLastChildProp: { type: Boolean, default: false },
    itemIndexProp: { type: Number, default: 0 },
  },
  components: {
    RecycleScroller,
  },
  created() {
    this.$vuetify.lang.current = "pt";
  },
  data() {
    return {
      autoCompleteKey: 0,
      loadingSelectionAll: false,
      selectAllData: false,
      isDraggable: false,
      searchedColumns: [],
      http: new ApiClient(),
      loadingOptions: false,
      queryTerm: "",
      dialog: false,
      itemsString: [
        { text: "IGUAL", value: "equals" },
        { text: "DIFERENTE", value: "not-equal" },
        { text: "CONTÉM", value: "contains" },
        { text: "NÃO CONTÉM", value: "not-contain" },
        { text: "NULO", value: "null" },
        { text: "NÃO NULO", value: "not-null" },
      ],
      itemsTemplateEmail: [
        { text: "ENVIADO", value: "sent" },
        { text: "ENVIADO RELATIVO", value: "sent-rel" },
        { text: "NÃO ENVIADO", value: "not-sent" },
        { text: "ABRIU", value: "opened" },
        { text: "ABRIU RELATIVO", value: "opened-rel" },
        { text: "NÃO ABRIU", value: "not-opened" },
        { text: "CLICOU", value: "clicked" },
        { text: "CLICOU RELATIVO", value: "clicked-rel" },
        { text: "NÃO CLICOU", value: "not-clicked" },
        { text: "RECEBEU", value: "received" },
        { text: "RECEBEU RELATIVO", value: "received-rel" },
        { text: "NÃO RECEBEU", value: "not-received" },
        { text: "BOUNCE", value: "bounce" },
        { text: "BOUNCE RELATIVO", value: "bounce-rel" },
        { text: "NÃO BOUNCE", value: "not-bounce" },
      ],
      itemsTemplateTag: [
        { text: "POSSUI", value: "has" },
        { text: "NÃO POSSUI", value: "hasnot" },
      ],
      itemsTemplateSms: [
        { text: "ENVIADO", value: "sent" },
        { text: "ENVIADO RELATIVO", value: "sent-rel" },
        { text: "NÃO ENVIADO", value: "not-sent" },
        { text: "CLICOU", value: "clicked" },
        { text: "CLICOU RELATIVO", value: "clicked-rel" },
        { text: "NÃO CLICOU", value: "not-clicked" },
      ],
      itemsNumber: [
        { text: "IGUAL", value: "equals" },
        { text: "MAIOR QUE", value: "greater" },
        { text: "MENOR QUE ", value: "lesser" },
        { text: "ENTRE ", value: "between" },
        { text: "NULO", value: "null" },
        { text: "NÃO NULO", value: "not-null" },
      ],
      itemsDate: [
        { text: "ANIVERSARIO", value: "equals-bday" },
        { text: "IGUAL RELATIVO", value: "equals-rel" },
        { text: "IGUAL", value: "equals" },
        { text: "MAIOR QUE", value: "greater" },
        { text: "MENOR QUE ", value: "lesser" },
        { text: "MAIOR QUE RELATIVO", value: "greater-rel" },
        { text: "MENOR QUE RELATIVO", value: "lesser-rel" },
        { text: "ENTRE RELATIVO", value: "between-rel" },
        { text: "ENTRE ", value: "between-date" },
        { text: "NULO", value: "null" },
        { text: "NÃO NULO", value: "not-null" },
      ],
      itemsBool: [
        { text: "IGUAL", value: "equals" },
        { text: "DIFERENTE", value: "not-equal" },
        { text: "NULO", value: "null" },
        { text: "NÃO NULO", value: "not-null" },
      ],
      itemsBlock: [
        { text: "OU", value: "OR" },
        { text: "E", value: "AND" },
      ],
      itemsRelative: [
        {
          text: "Hora",
          value: "hours",
        },
        {
          text: "Dia",
          value: "days",
        },
        {
          text: "Mês",
          value: "months",
        },
        {
          text: "Ano",
          value: "years",
        },
      ],
      itemsComparator: [
        {
          text: "=",
          value: "=",
        },
        {
          text: ">=",
          value: ">=",
        },
        {
          text: "<=",
          value: "<=",
        },
      ],
      itemsRelativeBday: [
        {
          text: "Dia",
          value: "days",
        },
        {
          text: "Mês",
          value: "months",
        },
      ],
      booleanColumns: [
        {
          text: "true",
          value: 1,
        },
        {
          text: "false",
          value: 0,
        },
        {
          text: "null",
          value: null,
        },
      ],
      searchTimeout: null,
    };
  },
  watch: {
    selectAllData(nv) {
      if (nv) this.selectAllInformations();
      else this.info.value = [];
    },
  },
  computed: {
    computedSearchColumns() {
      let arr = this.searchedColumns.map((e) => {
        return { text: e, value: e, formated_date: moment(e).format("DD/MM/YYYY") };
      });

      return arr;
    },
    search: {
      get() {
        return this.queryTerm;
      },

      set(searchInput) {
        if ((this.queryTerm !== searchInput && this.queryTerm) || searchInput) {
          // console.log("entrei", this.queryTerm, searchInput);
          this.queryTerm = searchInput;
          this.searchedColumns = [];
          this.loadingOptions = true;

          if (this.searchTimeout) {
            clearTimeout(this.searchTimeout);
          }
          this.searchTimeout = setTimeout(() => {
            // execute search function here
            this.searchColumnItems(this.info.operator, this.info, this.queryTerm);
          }, 750);
        }
      },
    },
  },
  methods: {
    formateDate(date) {
      const formated_date = moment(date).format("DD/MM/YYYY");
      const is_valid = moment(formated_date, "DD/MM/YYYY", true).isValid();
      return is_valid ? formated_date : date;
    },
    chageColumnsSize() {
      const s =
        this.info.operator == "equals" ||
        this.info.operator == "not-equal" ||
        this.info.operator == "between" ||
        this.info.operator == "between-date" ||
        this.info.operator == "not-contain" ||
        this.info.operator == "greater" ||
        this.info.operator == "lesser" ||
        this.info.operator == "greater-rel" ||
        this.info.operator == "lesser-rel" ||
        this.info.operator == "equals-rel" ||
        this.info.operator == "opened-rel" ||
        this.info.operator == "clicked-rel" ||
        this.info.operator == "received-rel" ||
        this.info.operator == "bounce-rel" ||
        this.info.operator == "equals-bday";
      return s;
    },
    shouldShowOptions(operator) {
      return (
        operator != "null" &&
        operator != "not-null" &&
        operator != "sent" &&
        operator != "opened" &&
        operator != "clicked" &&
        operator != "received" &&
        operator != "bounce" &&
        operator != "not-sent" &&
        operator != "not-opened" &&
        operator != "not-clicked" &&
        operator != "not-received" &&
        operator != "not-bounce"
      );
    },
    async selectAllInformations() {
      if (this.searchedColumns.length) {
        this.loadingSelectionAll = true;
        const ARR_SIZE = this.searchedColumns.length;
        if (ARR_SIZE > 20) this.$emit("loadingLargeData", true);

        this.info.value = [];
        // também pode ser usado this.info.value = this.searchedColumns; O método abaixo é útilo para não inserir items iguais dentro do array de forma rápida
        this.info.value = await this.isCointained(this.searchedColumns, []); // PASSO O ARRAY ZERADO, PORQUE TODOS IRÃO ENTRAR NO ARRAY DE QUALQUE FORMA, ENTÃO NÃO PRECISO SABER QUEM JÁ ESTAVA LA DENTRO
        // console.log("terminou de popular o array", this.info.value);

        this.autoCompleteKey++;
        this.loadingSelectionAll = false;
        this.$emit("loadingLargeData", false);
      }
    },

    binarySearch(arr, target) {
      let start = 0;
      let end = arr.lengt - 1;

      while (start <= end) {
        let mid = Math.floor((start + end) / 2);

        if (arr[mid] === target) {
          return true;
        } else if (arr[mid] < target) {
          start = mid + 1;
        } else {
          end = mid - 1;
        }
      }

      return false;
    },

    isCointained(list, arr) {
      arr.sort();

      for (let i = 0; i < list.length; i++) {
        if (!this.binarySearch(arr, list[i])) arr.push(list[i]);
      }

      return arr;
    },
    getChipValueStyle() {
      let STYLE = {
        width: "45%",
        color: "white",
      };

      if (this.getChipValue().length < 13) {
        STYLE.fontSize = "12.5px";
      } else {
        STYLE.fontSize = "65%";
      }

      return STYLE;
    },
    getChipValue() {
      // if (this.info.value && this.info.value.length === 1) {
      //   return `${this.info.value[0]}`;
      // } else if (this.info.value && this.info.value.length > 1) {
      //   return `${this.info.value[0]} + ${this.info.value.length - 1}`;
      // } else {
      //   return "Selecione um valor!";
      // }
      return this.info.value.length === 1
        ? `${this.info.value.length} seleção`
        : `${this.info.value.length} seleções`;
    },
    changeComboValue() {
      // console.log(this.info.value);
    },
    removeItemOnSelect(item) {
      this.info.value.splice(this.info.value.indexOf(item), 1);
    },
    pickElement(evt, item) {
      this.$emit("pickElement", evt, item);
    },
    onDropItem(evt, item, idx) {
      this.$emit("onDropItem", evt, item, idx);
    },
    async searchColumnItems(operator, item, queryTerm = "") {
      // CASO NÃO TENHA INFORMAÇÕES, ENTÃO É DISPARADO A REQUISIÇÃO PARA O BACKEND
      if (!this.searchedColumns.length) {
        this.loadingOptions = true;

        const operators = ["equals", "not-equal", "has", "hasnot"];

        if (operators.includes(operator)) {
          let URL = "";
          this.searchedColumns = [];
          switch (item.configurationType) {
            case "primaryData":
            case "tag":
              URL = "dbConfiguration/getDistinctColumn";
              break;
            case "additionalData":
              URL = "additionalDataConfiguration/getDistinctColumn";
              break;

            case "conversionData":
              URL = "conversionDataConfiguration/getDistinctColumn";
              break;
          }

          try {
            const req = await this.http.post(URL, {
              id: item.configurationId,
              column: item.field,
              query: queryTerm,
              type: item.type,
            });

            if (!this.info.value) this.info.value = [];
            this.searchedColumns = req;
            // if(req.length) this.searchedColumns.unshift('Selecionar todos')
            this.loadingOptions = false;
          } catch (error) {
            this.loadingOptions = false;
            this.$toast.error(`Erro ao trazer colunas para ${item.field || ""}`);
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
.theme--light.v-list-item.v-list-item--highlighted:before {
  opacity: 0;
}

.theme--light.v-list-item.v-list-item--active:before {
  opacity: 0.16;
}

.line {
  // border: 1px solid red;
  &__item {
    // border: 3px solid;
    display: flex;
    align-items: center;
    gap: 0.3rem;
  }
  .select-all-block {
    position: relative;
    .checkbox {
      position: absolute;
      bottom: -25px;
    }
  }
}

.wrapper {
  &__dialog {
    background: white;
    padding: 10px;
    border-radius: 15px;

    &__title {
      span {
        font-size: 1.2em;
        font-weight: 600;
      }
    }

    &__form {
      margin: 10px;
    }

    &--actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
    }
  }
}

.line__item > .v-input {
  padding-top: 10px !important;
  overflow-y: hidden;
}

.line__item > .v-btn,
.line__item > .v-icon {
  margin-top: 10px !important;
}

.line__item > .select-all-block {
}
</style>
