<template>
  <div class="wrapper">
    <div class="wrapper__header" @click="show = !show">
      <v-icon :class="{ 'fade-arrow': show }">$arrow_right_rounded</v-icon>
      <span>{{ info.name }}</span>
    </div>
    <transition name="fade">
      <div v-if="show" class="wrapper__body">
        <div
          v-for="(child, j) in info.children"
          :key="j"
          draggable="true"
          class="element"
          @dragstart="startDrag($event, child)"
        >
          <span>{{ child.name }}</span>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    info: { type: Object, default: null, required: true },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    startDrag(evt, child) {
      this.$emit("startDraggin", evt, child);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/main.scss";

.wrapper {
  height: auto !important;
  padding: 0 !important;
  &__header {
    cursor: pointer;
    .fade-arrow {
      transform: rotate(90deg);
    }
  }
  &__body {
    .element {
      margin-left: $z-s-2;
      cursor: pointer;

      span {
        font-weight: 500;
        font-size: 0.9rem;
      }
      &:not(:last-child) {
        margin-bottom: 0.2rem;
      }
    }
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
