var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        {
          staticClass: "wrapper__header",
          on: {
            click: function ($event) {
              _vm.show = !_vm.show
            },
          },
        },
        [
          _c("v-icon", { class: { "fade-arrow": _vm.show } }, [
            _vm._v("$arrow_right_rounded"),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.info.name))]),
        ],
        1
      ),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.show
          ? _c(
              "div",
              { staticClass: "wrapper__body" },
              _vm._l(_vm.info.children, function (child, j) {
                return _c(
                  "div",
                  {
                    key: j,
                    staticClass: "element",
                    attrs: { draggable: "true" },
                    on: {
                      dragstart: function ($event) {
                        return _vm.startDrag($event, child)
                      },
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(child.name))])]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }